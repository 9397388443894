import Clipboard from "clipboard";

/**
 * 复制
 * @param {*} _class
 */
export function copy(_class) {
  return new Promise((resolve, reject) => {
    let clipboard = new Clipboard("." + _class);
    console.log(clipboard);
    clipboard.on("success", (e) => {
      console.log("复制成功", e);//this.$t('menu.copySuc')
      resolve(window.vm.$i18n.t('menu.copySuc'))//复制成功 window.vm.$i18n.t('emailNotNull') this.$t('menu.copySuc')
      // 释放内存
      clipboard.destroy();
    });
    clipboard.on("error", (e) => {
      // 不支持复制
      reject(window.vm.$i18n.t('menu.notSupportCopying'))//该浏览器不支持自动复制
      console.log("该浏览器不支持自动复制", e);
      // 释放内存
      clipboard.destroy();
    });
  });
}

/**
 * 获取url参数
 * @param {string} url url地址
 * @param {string} variable 参数名称
 * @returns 
 */
export function  getQueryVariable(url,variable)
{
  var query = url.substring(url.lastIndexOf('?')+1);
  // console.log(query)
  var vars = query.split("&");
  for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
  }
  return(false);

}
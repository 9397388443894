<template>
<transition name="fadePage" mode="out-in">
<div class="my_recommendation">
  <Top-Nav></Top-Nav>
  <Loading-Dialog v-if="isLoading"></Loading-Dialog>
  <div :class="{node_title:true,node_title_height_min:!notHasRef} ">
    <div class="title">{{refCount}}</div>
    <div class="s_title">{{$t('menu.communityName')}}</div>
    <div class="adress_copy">
      <span >{{$t('menu.communityAdress')}}{{myRecUrl}}</span>
      <a href="javascript:;" @click="copy('copy_myRecUrl')" class="copy_myRecUrl" :data-clipboard-text="myRecUrl">{{$t('menu.copyCont')}}</a>
    </div>

    <div class="set_adress_rec" v-if="notHasRef">
      <div class="set_ref_address"><input type="text" :placeholder="$t('menu.superiorLink')" v-model="refAddress" ></div>
      <div class="btn_r"><a href="javascript:;" @click="setRef()"  :class="{disabled_a:isDisabl_a}">{{$t('menu.relatedPull')}}</a></div>
    </div>

  </div>
  <div class="node_table" :style="boxHeightObj">
    <div class="my_title">{{$t('menu.directPushAddress')}}</div>
    <div class="list_wrapper" :style="wrapHeightObj" ref="scroll">
    <!-- <div class="list_wrapper" :style="wrapHeightObj" ref="scroll" @scroll.passive="scrollEvent"> -->
      <div class="list">
        <div class="item" v-for="(item,index) in dataList" :key="index">
          {{item}}
        </div>
        <div class="s_title" v-if="isLodingOk"><button class="blue-text" @click="loadMore">{{loadText}}</button></div>
      </div>
    </div>
    
  </div>
</div>
</transition>
</template>
<script>
import '../../style/base.css';
import '../../style/myRecommendation.css';
import TopNav from '../../components/common/TopNav.vue';
import LoadingDialog from '../../components/common/LoadingDialog.vue';

const starPool = require('../../utils/starPool')
const util = require('../../utils/util')

export default {
  name: 'MyRecommendation',
  components:{TopNav,LoadingDialog},
  data() {
    return {
      isLoading:true, //加载蒙版
      notHasRef:false, //没有推荐人
      windowHeight: window.innerHeight,   //屏幕高度
      boxHeightObj:{  //背景框的高度
        height:0
      },
      wrapHeightObj:{
        height:0
      },
      refCount:0,// 一级推荐人数
      myRecUrl:'',
      dataList:[],
      refAddress:'',//推荐人地址
      isDisabl_a:false,//禁用a标签
      first:15,//每页展示数量
      skip:0,//开始位置
      flag :0,    // 定义一个flag变量 充当节流阀
      wait:1000,    // 定义一个wait变量 延迟多长时间触发
      isLodingOk:true,
      loadText:this.$t('menu.loadMoreText')
    }
  },
  created:function(){

    let that = this
    let myAddress = localStorage.getItem('address')
    if(!myAddress || typeof myAddress == "undefined" || myAddress == 0){
      //console.log('没有钱包地址不获取数据',myAddress)
      return 
    }

    // 获取推荐人
    starPool.getRef().then(res=>{
      //console.log('获取推荐人',res)
      that.refCount = res.refCount
      that.notHasRef = (res.ref == '0x0000000000000000000000000000000000000000')
    })

    // 生成我的社区地址
    let myRecUrl = window.location.origin + '?ref=' +myAddress
    this.myRecUrl = myRecUrl

    // 获取社区地址列表
    this.getRefListData()

    //推荐奖励信息
    // starPool.userRefInfo().then(res=>{
    //   console.log(res)
    //   // that.refCount = res._refCount
    // })



  },

  mounted(){
    const _this = this;
    _this.boxHeightObj.height = (_this.windowHeight/10)-5-23+'rem';
    _this.wrapHeightObj.height = (_this.windowHeight/10)-5-23-3.4+'rem';
  },
  methods:{
    loadMore(){
      this.first +=1;
      this.getRefListData();
    },
    // 滚动事件
    // scrollEvent() {
    //   // 获取容器盒子的dom
    //   let that =this
    //   let scroll= this.$refs.scroll;
    //   let flag =this.flag
    //   let wait = this.wait

    //   // console.log('获取容器盒子的dom',scroll)
    //   // 判断滚动条距离容器底部的位置 当滚动条距离容器底部只有5像素时触发
    //   if (Math.ceil(scroll.scrollHeight - scroll.scrollTop) == scroll.clientHeight) {
    //     if (flag) return;
    //     flag = 1; // 执行完一次后 关闭节流阀
    //     if (that.isLodingOk) return;
        
    //     // setTimeout 根据个人需求来定 
    //     that.isLoading = true
    //     setTimeout(() => {
    //       // 需要执行的操作 执行完成后开启节流阀flag = 0;
    //       // 如果不需要在进行操作 flag无需改变或者flag = 1;
    //       // that.getRefListData()
    //     }, wait);
    //   }
    // },

    // 获取社区地址列表
    getRefListData(){
      let that = this;
      let first = that.first;
      let skip = that.skip;
      let myAddress = localStorage.getItem('address')
      // let myAddress ='0x73a705f9354a86952D57B129453F43457Be70e72'
      var query = `query MyQuery {
        refEventModels(
          where: {ref: "`+myAddress+`"}
          first: `+first+`
          skip: `+skip+`
        ) {
          id
          account
          ref
        }
      }`

      that.skip += first
      
      starPool.referelGraphql(query).then(res=>{
        let refEventModels = res.data.refEventModels
        // console.log(res,'===============');
        that.isLoading = false;
        // that.flag = 0
        let refEventModelsLength =  refEventModels.length;
        let dataListLength =  that.dataList.length;
        
        if(refEventModelsLength<that.first&&refEventModelsLength!=0){
          that.isLodingOk = false // 总数小于请求条数，隐藏加载更多按钮
        }
        if(refEventModelsLength>0){
          let dataList = that.dataList
          // 我的社区列表显示中间显示成***,不把地址全部显示 0x8d9...059C
          refEventModels.forEach(item=>{
            let account = item.account
            account = account.substr(0,15) + "..." + account.substring(account.length-15)
            dataList.push(account)
          })
          that.dataList = dataList;
        }
        if(refEventModelsLength==0){
          that.loadText = this.$t('menu.noDataText');//暂无数据
        }else{
          if(refEventModelsLength==dataListLength){
            that.loadText = this.$t('menu.noMoreText');//没有更多了
          }else{
            that.loadText = this.$t('menu.loadMoreText');//点击加载更多
          }
          
        }
      })
    },
    copy(_class){
      //console.log('copy',_class)
      util.copy(_class).then(res=>{
        this.$toast(res);
      })
    },
    //设置推荐人
    setRef(){
      let refAddress = this.refAddress
      // 解析推荐人地址
      if(refAddress.lastIndexOf('?') >0){
        refAddress = util.getQueryVariable(refAddress,'ref')
        if(refAddress == false){
          this.$toast(this.$t('menu.parsingFailed'));
        }
      }
      let that = this
      that.isDisabl_a = true
      // 设置推荐人
      starPool.setRef(refAddress).then(res=>{
        that.isDisabl_a = false
        console.log('设置推荐人结果',res)
        if(res && typeof res !== "undefined" && res !== 0){
          this.$toast(res);
        }
          
          
      })
      
    }
  }
}
</script>
<style scoped>
.node_title_height_min{
  height:12rem;
}
</style>